/* You can add global styles to this file, and also import other style files */
body.intercom-open {
  bw-modal,
  .modal-container {
    margin-right: 400px;
  }

  .content-inner {
    &.onboarding-complete {
      margin-right: 400px;
    }
  }

  /* Override for full screen because.. I don't know how else to handle right now */
  .full-screen {
    bw-modal,
    .modal-container {
      margin-right: 0;
    }

    .content-inner {
      &.onboarding-complete {
        margin-right: 0;
      }
    }
  }
}

.waitlist,
.get_started {
  bw-topbar .topbar-wrapper {
    background-color: transparent;
  }
}

.waitlist,
.get_started {
  bw-topbar .topbar-wrapper {
    background-color: transparent;
  }
}

.waitlist,
.get_started {
  bw-topbar .topbar-wrapper {
    background-color: transparent;
  }
}

.bs-blue {
  color: var(--blue-50);
}

#topic-page {
  .content-container {
    overflow: hidden;
    box-shadow: 0px 0px 0px rgba(7.52731628715992, 35.138481482863426, 47.88209706544876, 0.23999999463558197);
    background-color: white;
    opacity: 1;
    padding: 0;
    max-width: 800px; /* 800px (Instead of 768px) is to allow 2 column layouts in inner content */
    margin: 0 auto;
  }

  .header-container {
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
    // min-height: 120px;
    margin-bottom: 32px;
    margin-top: 0;
    position: relative;
  }

  .top-navigation {
    z-index: 1;
    padding: 0 16px 0 0;
    height: 48px;
    background-color: rgba(255, 255, 255, 1);
  }

  .back-navigation {
    border: none;
    background: none;
    padding: 0;
    margin: 14px 0 42px;
    cursor: pointer;
  }

  .page-title {
    color: #1b1b1b;
    opacity: 1;
    text-align: left;
    font-size: 32px;
    letter-spacing: -0.02;
    line-height: 40px;
    font-weight: 600;
    width: calc(100% - 96px);
    margin: 0 0;
    // position: absolute;
    bottom: 0;
  }

  .header-graphic {
    position: absolute;
    right: -16px;
    bottom: 0;
    width: 120px;
  }

  .leading-copy {
    color: #757575;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .section-heading {
    font-size: 16px;
    letter-spacing: -0.02;
    line-height: 24px;
    margin: 0 0 8px;
    font-weight: 600;
  }

  .section-copy {
    color: #1b1b1b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 8px;
  }

  .section-copy:last-of-type {
    margin: 0;
  }

  .white {
    color: #ffffff;
  }

  .light-blue {
    color: #f2f7ff;
  }

  .caption {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 8px;
  }

  .story-title {
    font-size: 18px;
    letter-spacing: -0.02;
    line-height: 24px;
    margin: 0 0 24px;
    font-weight: 600;
  }

  .story-copy {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .roi {
    position: relative;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.07999999821186066);
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 16px 0 32px;
    overflow: hidden;
  }

  .roi-dyn-text {
    position: absolute;
    left: 16px;
    top: 52px;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.02;
    color: #005fff;
  }

  .roi-graphic {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .roi-graphic img {
    width: 100%;
  }

  .roi-copy {
    padding: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #1b1b1b;
  }

  .cell-action {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    box-sizing: border-box;
  }

  .cell-action-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #005fff;
  }

  .cell-action-icon {
    width: 24px;
    height: 24px;
  }

  .value-prop {
    margin-bottom: 32px;
  }

  .value-prop-icon {
    margin-bottom: 24px;
  }

  .call-fa-card-wrapper {
    background: #f2f7ff;
    background-image: url('/assets/Talk-to-FA.svg');
    background-position: bottom right;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-bottom: 32px;
  }

  .call-fa-card-inner {
    padding: 24px;
  }

  .call-fa-caption {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #005fff;
    margin: 0 0 8px;
  }

  .call-fa-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02;
    color: #003999;
    width: calc(100% - 65px);
    margin: 0 0 16px;
  }

  .call-fa-button {
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    padding: 8px 16px;
    width: 106px;
    height: 40px;
    background: #005fff;
    border-radius: 24px;
  }

  .no-bottom-space {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  body.intercom-open {
    bw-modal,
    .modal-container {
      width: 600px !important;
    }
  }
}

/* ToDo: should this break point be 1280px */
@media screen and (min-width: 320px) and (max-width: 1024px) {
  body.intercom-open {
    bw-modal,
    .modal-container {
      width: 500px !important;
    }
  }
}

@media print {
  @page {
    size: A4;
    margin: 2cm;
  }

  .hidden-print,
  .action-header,
  hr {
    display: none !important;
  }

  .content-wrapper .content-inner {
    margin-left: 0 !important;

    a {
      text-decoration: none;
      color: var(--secondary-text);
    }
  }
}

body.not-logged-in.signup {
  .intercom-frame, .intercom-lightweight-app {visibility: hidden};
}

.bwClick {
  cursor: pointer;
}
