.versionStamp {
  color: #f2f7ff;
}

.bs-blue {
  color: var(--blue-50);
}

.keyboard-filler-space {
  margin-bottom: 320px;
}
